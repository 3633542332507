require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

// Echo.private(`App.Models.User.` + window.Laravel.userId)
//     .listen('.vocalprint.initiated', (e) => {
//         $("#vocal-print-initialized").text("Vocal Print in process. Please follow the instructions on your call.");
//         $("#vocal-print-initialized").css({ 'color': 'red', 'font-size': '150%' });
//         $("#vocal-print-call-in-progress").text("Connection established. Please follow the on call instructions");
//         $("#vocal-print-div").hide();
//         $("#vocal-print-sound-wave").show();
//     })
//     .listen('.vocalprint.enrolled', (e) => {
//         alert("You have successfully enrolled your vocal print. You will now be returned back to your course.");
//         location.reload();
//     }).listen('.vocalprint.prompt.passed', (e) => {
//         alert("You have successfully validated your vocal print. You will now be asked a security question before you can continue.");
//         location.reload();
//     }).listen('.vocalprint.prompt.failed', (e) => {
//         $("#vocal-print-initialized").append("<br>You have failed an attempt at a vocal print");
//     });

// sweetalert2
class pageDialogs {

    static sweetAlert2() {
        // Set default properties
        let toast = Swal.mixin({
            buttonsStyling: false,
            target: '#page-container',
            customClass: {
                confirmButton: 'btn btn-success m-1',
                cancelButton: 'btn btn-danger m-1',
                input: 'form-control'
            }
        });
    }

    static init() {
        this.sweetAlert2();
    }
}

// Initialize when page loads
if (typeof One !== 'undefined' && One !== null) {
  One.onLoad(pageDialogs.init());
}

